import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../swipestyles.css";
import exit from "../images/exit.png";
import { loremIpsum } from "lorem-ipsum"; // Import lorem ipsum generator
import solidGreyBackground from "../images/roku.jpg";
import roku from "../images/roku.jpg";
import zim from "../images/zim.jpg"; // Import solid grey background image
import axios from "axios";
import emptys from '../images/Starempty-star.png';
import filleds from '../images/Starfilled-star.png';
import { useUser } from '../context/UserContext';

const Swiping = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const [startX, setStartX] = useState(null);
  const { user, isLoggedIn } = useUser();
  const [offsetX, setOffsetX] = useState(0);
  const navigate = useNavigate();
  const verticalContentRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentStock, setCurrentStock] = useState(0);
  const numberOfPages = 4;
  const [stockData, setStockData] = useState([]);
  const [BackupStockData, setBackupStockData] = useState([]);
  const [sector, setSector] = useState("-");
  const [industryA, setIndustry] = useState("-");
  const [Nextsector, setNextSector] = useState("-");
  const [Nextindustry, setNextIndustry] = useState("-");
  const [imageSrc, setImageSrc] = useState("");
  const [nextImageSrc, setNextImageSrc] = useState("");
  const [firstimg, setfirstimg] = useState(false);
  const [bio, setBio] = useState("");
  const [nextBio, setNextBio] = useState("");
  const [bioReady, setBioReady] = useState(false);
  const [picks, setPicks] = useState([]);
  const [nope, setNope] = useState([]);
  const [all, setAll] = useState([]);
  const [ticker, setTicker] = useState('');
  const [hasETF, setHasETF] = useState(false);
  const [nextHasETF, setNextHasETF] = useState(false);
  const [targetSum, setTargetSum] = useState(100); // State to store the target sum
  const [sumOfAmounts, setSumOfAmounts] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [perStock, setPerStock] = useState(0);
  //NjBEzizi0Mf7ZKBzsvNxvV6-7mtY1sD_OUY37BSBmvk
  const unsplashAccessKey = "NjBEzizi0Mf7ZKBzsvNxvV6-7mtY1sD_OUY37BSBmvk";

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    navigate(-1); // Navigate back to the previous route
  };

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        // Remove from favorites
        await axios.post('https://app.stocksleuth.xyz:5000/auth/removeFavorite', { ticker, displayName: user.displayname });
      } else {
        // Add to favorites
        await axios.post('https://app.stocksleuth.xyz:5000/auth/addFavorite', { ticker, displayName: user.displayname });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Failed to toggle favorite', error);
    }
  };

  useEffect(() => {
    // Calculate the sum of amounts whenever it changes
    const sum = picks.reduce((total, pick) => total + pick.amount.perStock, 0);
    setSumOfAmounts(sum);
    
  }, [picks]);
  const handleMouseDown = (event) => {
    setStartX(event.clientX);
  };
  //useEffect(() => {}, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch high-priority stocks from the API
        const response = await fetch("https://app.stocksleuth.xyz:5000/stockswhighPriority");
        const responseData = await response.json();
    
        // Sort the stocks by marketCap (descending order)
        const sortedStocks = responseData.sort((a, b) => b.marketCap - a.marketCap);
    
        const currentIndex = currentStock;
        const nextStocks = sortedStocks.slice(currentIndex, currentIndex + 2);
        setStockData(nextStocks);
        setBackupStockData(nextStocks);
    
        // Fetch quotes for the first stock
        const proxyUrl = "https://app.stocksleuth.xyz:5000/call?url=";
        const apiUrl = `https://query1.finance.yahoo.com/v1/finance/search?q=${nextStocks[0].symbol}`;
        const encodedApiUrl = encodeURIComponent(apiUrl);
        const response2 = await axios.get(proxyUrl + encodedApiUrl);
        const quotes = response2.data.quotes;
    
        // Fetch quotes for the second stock
        const apiUrl2 = `https://query1.finance.yahoo.com/v1/finance/search?q=${nextStocks[1].symbol}`;
        const encodedApiUrl2 = encodeURIComponent(apiUrl2);
        const response3 = await axios.get(proxyUrl + encodedApiUrl2);
        const quotes2 = response3.data.quotes;
    
        if (quotes.length > 0) {
          const { sector, industry, quoteType, symbol } = quotes[0];
          setTicker(symbol);
          if (sector && industry) {
            setSector(sector);
            setIndustry(industry);
          }
    
          // Check if the quoteType is "ETF"
          if (quoteType === "ETF") {
            setHasETF(true);
            setSector("");
            setIndustry("");
            setImageSrc(roku);
          } else {
            setHasETF(false);
            if (!firstimg) {
              try {
                const unsplashResponse = await axios.get(
                  `https://api.unsplash.com/photos/random?query=${encodeURIComponent(industry)}&client_id=${unsplashAccessKey}`
                );
                setImageSrc(unsplashResponse.data.urls.regular);
                setfirstimg(true);
              } catch {
                setImageSrc(roku);
              }
            }
          }
    
          const bioProxyUrl = "https://app.stocksleuth.xyz:5000/scrapebio?ticker=";
          if (!bioReady || bio === 'loading...' || bio === 'no bio found') {
            try {
              const responseNASDAQ = await axios.get(bioProxyUrl + encodeURIComponent(symbol));
    
              let bioData = "";
              if (responseNASDAQ.data.content !== "") {
                bioData = responseNASDAQ.data.content;
              } else {
                setBio('loading...');
                bioData = 'no bio found';
              }
              setBio(bioData);
            } catch (error) {
              console.error(error);
            }
            setBioReady(true);
          }
        }
    
        if (quotes2.length > 0) {
          const { sector, industry, quoteType, symbol } = quotes2[0];
          if (sector && industry) {
            setNextSector(sector);
            setNextIndustry(industry);
          }
    
          // Check if the quoteType is "ETF"
          if (quoteType === "ETF") {
            setNextHasETF(true);
            setNextSector("");
            setNextIndustry("");
            setNextImageSrc(roku);
          } else {
            setNextHasETF(false);
            try {
              /*const unsplashResponse2 = await axios.get(
                `https://api.unsplash.com/photos/random?query=${encodeURIComponent(industry)}&client_id=${unsplashAccessKey}`
              );
              setNextImageSrc(unsplashResponse2.data.urls.regular);*/
            } catch {
              setNextImageSrc(zim);
            }
          }
    
          const bioProxyUrl2 = "https://app.stocksleuth.xyz:5000/scrapebio?ticker=";
          try {
            const responseNASDAQ = await axios.get(bioProxyUrl2 + encodeURIComponent(symbol));
    
            let bioData = "";
            if (responseNASDAQ.data.content !== "") {
              bioData = responseNASDAQ.data.content;
            } else {
              setNextBio('loading...');
              bioData = 'no bio found';
            }
            setNextBio(bioData);
          } catch (error) {
            console.error(error);
          }
        }
    
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
    
  }, [currentStock]);

  const handleMouseMove = (event) => {
    if (startX !== null) {
      const newOffsetX = event.clientX - startX;
      //console.log(newOffsetX);
      setOffsetX(newOffsetX);
      const maxRotation = 10; // Maximum rotation angle in degrees
      const rotationFactor = newOffsetX / 10; // Adjust rotation based on offsetX
      const rotation =
        Math.min(Math.abs(rotationFactor), maxRotation) *
        Math.sign(rotationFactor);

      if (verticalContentRef.current) {
        verticalContentRef.current.style.transition = "none"; // Disable transition during drag
        verticalContentRef.current.style.transform = `translateX(${newOffsetX}px) rotate(${rotation}deg)`;
      } else {
        console.log("F?");
      }
    }
    if (sumOfAmounts >= targetSum) {
      alert(`Sum of amounts has reached ${targetSum}`);
    }
  };
  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);
  const handleMouseUp = () => {
    if (Math.abs(offsetX) > 100) {
      // Log the action based on the direction of the swipe
      if (offsetX > 0) {
        console.log("Swiped right");
        setCurrentStock(currentStock + 1);
        setPicks([...picks, {stock: {ticker}, amount: {perStock}}]);
        console.log([...picks, {stock: {ticker}, amount: {perStock}}])
      } else {
        console.log("Swiped left");
        setCurrentStock(currentStock + 1);
        setNope([...nope, {stock: {ticker} }]);
      }
      setImageSrc(nextImageSrc);
      setBio(nextBio);
      fisrtPage(); // Check if currentPage is reset
      setAll([...all, {stock: {ticker}}]);
    }
    setStartX(null);
    setOffsetX(0); // Reset offsetX to 0

    if (verticalContentRef.current) {
      verticalContentRef.current.style.transition = "transform 0.3s ease"; // Re-enable transition after drag
      verticalContentRef.current.style.transform = "translateX(0) rotate(0)";
    }
  };

  //useEffect(() => {console.log(offsetX);});
  useEffect(() => {
    //console.log(offsetX);

    const updateRotation = () => {
      // Calculate rotation based on offsetX
      const maxRotation = 10; // Maximum rotation angle in degrees
      const rotationFactor = offsetX / 10; // Adjust rotation based on offsetX
      const rotation =
        Math.min(Math.abs(rotationFactor), maxRotation) *
        Math.sign(rotationFactor);

      // Apply rotation to the element
      if (verticalContentRef.current) {
        verticalContentRef.current.style.transition = "transform 0.7s ease"; // Disable transition during drag
        verticalContentRef.current.style.transform = `translateX(${offsetX}px) rotate(${rotation}deg)`;
      }

      // Continue updating rotation
      requestAnimationFrame(updateRotation);
    };

    // Start updating rotation
    updateRotation();

    document.addEventListener("mousemove", handleMouseMove); // Attach mousemove event listener to document
    return () => {
      document.removeEventListener("mousemove", handleMouseMove); // Clean up event listener on component unmount
    };
  });
  const handleLeft = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? numberOfPages - 1 : prevPage - 1
    ); // If on the first page, go to the last page; otherwise, decrement by 1
  };
  const fisrtPage = () => {
    setTimeout(() => {
      setCurrentPage(0);
      console.log("Current page reset to 0:", currentPage); // Log after resetting currentPage
    }, 50);
  };
  const handleRight = () => {
    setCurrentPage((prevPage) =>
      prevPage === numberOfPages - 1 ? 0 : prevPage + 1
    ); // If on the last page, go to the first page; otherwise, increment by 1
  };

  return (
    showOverlay && (
      <div
        className="overlay Background"
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className="TempInfo">
          <div>
            {/* Number input field for setting the target sum */}
            <label htmlFor="targetSum">Target Sum:</label>
            <input
              type="number"
              id="targetSum"
              value={targetSum}
              onChange={(e) => setTargetSum(parseInt(e.target.value))}
            />
          </div>
          <div>
            {/* Input field for amount per stock */}
            <label htmlFor="perStock">Amount Per Stock:</label>
            <input
              type="number"
              id="perStock"
              value={perStock}
              onChange={(e) => setPerStock(parseInt(e.target.value))}
            />
          </div>
        </div>

        <div className = "history2">
        {picks.map((pick, index) => (
  <div key={index}>
    <p>{pick.stock.ticker} | {pick.amount.perStock}</p>
  </div>
))}</div>
        <img
          src={exit}
          onClick={handleCloseOverlay}
          className="exitButton"
          alt="Exit"
        />
        <div className="page">
          <div
            className="verticalContent"
            ref={verticalContentRef}
            onMouseDown={handleMouseDown}
            style={{
              backgroundColor: 'black',
              transform: "translateX(${offsetX}px)",
            }}
          >
            <div className="containerB">
              <div
                className="itemB"
                style={{
                  backgroundColor:
                    currentPage === 0 ? "#FFFFFF" : "rgba(171, 159, 171, 0.5)",
                }}
              ></div>
              <div
                className="itemB"
                style={{
                  backgroundColor:
                    currentPage === 1 ? "#FFFFFF" : "rgba(171, 159, 171, 0.5)",
                }}
              ></div>
              <div
                className="itemB"
                style={{
                  backgroundColor:
                    currentPage === 2 ? "#FFFFFF" : "rgba(171, 159, 171, 0.5)",
                }}
              ></div>
              <div
                className="itemB"
                style={{
                  backgroundColor:
                    currentPage === 3 ? "#FFFFFF" : "rgba(171, 159, 171, 0.5)",
                }}
              ></div>
            </div>
            <div className="leftSide" onClick={() => handleLeft()} />
            <div className="rightSide" onClick={() => handleRight()} />
            <div className="inside">
              {stockData.map((stock, index) => (
                <div
                  key={index}
                  style={{ display: 0 === index ? "block" : "none" }}
                >
                  <p
                    className="CompName"
                    style={{
                      userSelect: "none",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "90%",
                    }}
                  >
                    {stock.symbol} <img style={{top: 40}}className="star" alt="favorite" src={isFavorite ? filleds : emptys} onClick={toggleFavorite} />
                  </p>
                  <p className="CompTckr" style={{
                      userSelect: "none",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "90%",
                    }}>
                    {stock.security_name}
                  </p>
                  
                </div>
              ))}
              {currentPage === 0 && (
                <>
                  <div className="tags-container">
                    {sector && sector !== "" && (
                      <div
                        className="tags"
                        style={{ width: `${sector.length * 9.5}px` }}
                      >
                        {sector}
                      </div>
                    )}
                    {industryA && industryA !== "" && (
                      <div
                        className="tags2"
                        style={{ width: `${industryA.length * 9.5}px` }}
                      >
                        {industryA}
                      </div>
                    )}
                    {hasETF && <div className="tagsETF">ETF</div>}
                  </div>

                  <p className="bio" style={{ userSelect: "none" }}>
                    {bio}
                  </p>
                </>
              )}

              {currentPage === 1 && (
                <p className="chart" style={{ userSelect: "none" }}>
                  Chart
                </p>
              )}
            </div>
          </div>
          {/* Second card positioned behind the first one 
          
          
          
            backgroundImage: `url(${imageSrc})`,*/
          }
          <div
            className="verticalContent"
            style={{
              backgroundColor: 'black',
              position: "fixed",
              top: 0,
              zIndex: -1,
            }}
          >
            <div className="containerB">
              <div
                className="itemB"
                style={{ backgroundColor: "#FFFFFF" }}
              ></div>
              <div
                className="itemB"
                style={{ backgroundColor: "rgba(171, 159, 171, 0.5)" }}
              ></div>
              <div
                className="itemB"
                style={{ backgroundColor: "rgba(171, 159, 171, 0.5)" }}
              ></div>
              <div
                className="itemB"
                style={{ backgroundColor: "rgba(171, 159, 171, 0.5)" }}
              ></div>
            </div>
            <div className="inside">
              {stockData.map((stock, index) => (
                <div
                  key={index}
                  style={{ display: 1 === index ? "block" : "none" }}
                >
                  <p
                    className="CompName"
                    style={{
                      userSelect: "none",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "90%",
                    }}
                  >
                    {stock.symbol}
                  </p>
                  <p className="CompTckr" style={{
                      userSelect: "none",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "90%",
                    }}>
                    {stock.security_name}
                  </p>
                </div>
              ))}

              <div className="tags-container">
                {Nextsector && Nextsector !== "" && (
                  <div
                    className="tags"
                    style={{ width: `${Nextsector.length * 9.5}px` }}
                  >
                    {Nextsector}
                  </div>
                )}
                {Nextindustry && Nextindustry !== "" && (
                  <div
                    className="tags2"
                    style={{ width: `${Nextindustry.length * 9.5}px` }}
                  >
                    {Nextindustry}
                  </div>
                )}

                {nextHasETF && <div className="tagsETF">ETF</div>}
              </div>
              <p className="bio" style={{ userSelect: "none" }}>
                {nextBio}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Swiping;
