import React, { useState, useContext, useEffect, useRef } from 'react';
import UserContext from '../context/UserContext';
import axios from 'axios';
import cookies from 'js-cookie';
import seePass from '../images/seePassword.png';
import defUser from '../images/defaultuser.jpg';
import '../Login.css';
import minilogo from '../images/Kopia_STOCK_SLEUTH-removebg-preview-1.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';


const SignUp = () => {
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordC, setPasswordC] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const imageRef = useRef(null);
  const [usesGoogle, setUsesGoogle] = useState(false);
  const [image, setImage] = useState(null);
  const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};
	const handleTogglePasswordC = () => {
		setShowPasswordC(!showPasswordC);
	};
	
	const handleImageChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the canvas dimensions to your desired size, e.g., 128x128
        canvas.width = 128;
        canvas.height = 128;

        // Draw the image on the canvas with the desired size
        ctx.drawImage(img, 0, 0, 128, 128);

        // Convert the canvas content to a Blob object (compressed image file)
        canvas.toBlob((blob) => {
          setImage(blob);
        }, 'image/jpeg', 0.7);
      };
    };

    reader.readAsDataURL(file);
  }
};

	const handleGoogleSignUp = useGoogleLogin({
  onSuccess: async (tokenResponse) => {
    try {
      // Retrieve user information from Google API
      const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          'Authorization': `Bearer ${tokenResponse.access_token}`,
        },
      });

      setUsesGoogle(true);

      if (!userInfo.ok) {
        throw new Error('Failed to fetch user information');
      }

      const userData = await userInfo.json();

      // Extract relevant information
      const { name, email, picture } = userData;

      // Log the user information
      console.log('User Information:', {
        username: name,
        email: email,
        profilePicture: picture,
        usesGoogle: true,
      });

      const img = new Image();
      img.crossOrigin = 'anonymous'; // Important if the image is from a different domain

      img.src = picture;
		console.log(img.src);
      img.onload = async () => {
		  
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the canvas dimensions to your desired size, e.g., 128x128
        canvas.width = 128;
        canvas.height = 128;
		
        // Draw the image on the canvas with the desired size
        ctx.drawImage(img, 0, 0, 128, 128);

        // Convert the canvas content to a Blob object (compressed image file)
        canvas.toBlob(async (blob) => {
          // Now 'blob' contains the compressed image file
          const formData = new FormData();
          formData.append('username', name);
          formData.append('email', email);
          formData.append('password', '-');
			formData.append('usesgoogle', true);
          if (blob) {
            formData.append('image', blob);
          } else {
            // Append the default user image
            const defaultImageBlob = await fetch(defUser).then((res) => res.blob());
            formData.append('image', defaultImageBlob, 'defaultuser.jpg');
          }

          // Make an API request to the signup endpoint
		  
          const response = await axios.post('https://app.stocksleuth.xyz:5000/auth/signup', formData);
          const imageUrl = response.data.profile_image;

          if (imageUrl !== null) {
            console.log('Image URL:', imageUrl);
          }

          if (rememberMe) {
            // Set the cookie with a longer expiration time (30 days)
            cookies.set('authToken', response.data.token, { expires: 30 });
          }

          // Once signed up, set the user in the context
          setUser({ ...response.data });
          console.log('Sign up successful:', response.data);
        }, 'image/jpeg', 0.7);
      };
    } catch (error) {
      console.error('Error handling Google sign-up:', error);
    }
  },
});

	useEffect(() => {
  console.log('Image state:', image);
}, [image]);
  const handleSignUp = async () => {
  // Email validation using a regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setEmailError('Invalid email address');
    return;
  } else {
    setEmailError('');
  }

  // Password strength check
  if (password.length < 8) {
    setPasswordError('Password must be at least 8 characters long');
    return;
  } else {
    setPasswordError('');
  }

  // Check if password and confirm password match
  if (password !== passwordC) {
    setPasswordError('Passwords do not match');
    return;
  } else {
    setPasswordError('');
  }

  try {
    // Create a FormData object to handle file uploads
    const formData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
	formData.append('usesgoogle', false);
	
    if (image) {
    formData.append('image', image);
	  } else {
		// Append the default user image
		const defaultImageBlob = await fetch(defUser).then((res) => res.blob());
		formData.append('image', defaultImageBlob, 'defaultuser.jpg');
	  }

    // Make an API request to the signup endpoint in your Node.js backend running locally
    const response = await axios.post('https://app.stocksleuth.xyz:5000/auth/signup', formData);
	const imageUrl = response.data.profile_image;
	if (response.data.profile_image !== null) {
      console.log('Image URL:');
    }
    if (rememberMe) {
      // Set the cookie with a longer expiration time (30 days)
      cookies.set('authToken', response.data.token, { expires: 30 });
    }

    // Once signed up, set the user in the context
    setUser({ ...response.data});
    console.log('Sign up successful:', response.data);
  } catch (error) {
    console.error('Sign up failed', error);
  }
};


  return (
    <div style = {{ textAlign: "center", alignItems: 'center',justifyContent: 'center', width: '100vw', height: '100vh', display: 'flex', background: '#FFF'}}>
		<img className="logoTop" src={minilogo}/>
		<h2 className="TopName">StockSleuth</h2>
		<div className="AlreadyAccount">
			<h3 className="SignInTo">Sign Up for</h3>
			<h4 className="YourAcc">A StockSleuth Account</h4>
			<p className="text1">If you already have an account,</p>
			<p className="text2">You can <span style={{color: '#4D47C3', fontWeight: '600'}}><Link to="/SignIn" style={{color: '#4D47C3', fontWeight: '600', textDecoration: 'none'}}>Login here !</Link></span></p>
			{/*<img className="person" src={saly}/>*/}
		</div>
		
		<div className="signInForm">
			<h2 className = "LoginHeader" style = {{margin: "0px", position: 'relative', left: 0, top: 0, textAlign: 'left'}}>Sign Up</h2>
			
			<div className = "EmailInputUp">
			<input className="EmailPlaceholder" style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }}type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</div>
			
			<div className = "UsernameInputUp">
			<input className='UserPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type='text' placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
			
			</div>
			
			<div className = "PasswordInputUp">
			<input className='passPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
			<img onClick={handleTogglePassword} style={{position: 'absolute',top: '23px', right: '32px'}} src={seePass} />
			</div>
			
			<div className = "ConfirmPasswordInputUp">
			<input className='passPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type={showPasswordC ? 'text' : 'password'} placeholder="Confirm Password" value={passwordC} onChange={(e) => setPasswordC(e.target.value)} />
			<img onClick={handleTogglePasswordC} style={{position: 'absolute',top: '23px', right: '32px'}} src={seePass} />
			</div>
			
			{passwordError && <p style={{ color: 'black' }}>{passwordError}</p>}
			<div className="SignUpButton"onClick={handleSignUp}><p className="LogIn"style={{margin: '0px'}}>Create An Account</p></div>
			<p className="ContinueWith">or continue with</p>
			<svg onClick={() => handleGoogleSignUp()} className="GoogleLogo" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
			<path d="M39.4108 21.1315C39.4108 19.6402 39.2873 18.5519 39.02 17.4234H21.6434V24.1543H31.8431C31.6375 25.8271 30.5271 28.3462 28.0593 30.0389L28.0247 30.2643L33.5189 34.4354L33.8996 34.4727C37.3954 31.3086 39.4108 26.6533 39.4108 21.1315Z" fill="#4285F4"/>
			<path d="M21.6422 38.8662C26.6392 38.8662 30.8342 37.2539 33.8984 34.4728L28.0581 30.0391C26.4953 31.1072 24.3977 31.8528 21.6422 31.8528C16.748 31.8528 12.5941 28.6889 11.1133 24.3157L10.8963 24.3338L5.18338 28.6667L5.10867 28.8702C8.15212 34.7952 14.4036 38.8662 21.6422 38.8662Z" fill="#34A853"/>
			<path d="M11.1144 24.3158C10.7237 23.1873 10.4976 21.978 10.4976 20.7286C10.4976 19.479 10.7237 18.2699 11.0939 17.1414L11.0835 16.901L5.29899 12.4986L5.10973 12.5868C3.85537 15.0455 3.13562 17.8064 3.13562 20.7286C3.13562 23.6508 3.85537 26.4116 5.10973 28.8703L11.1144 24.3158Z" fill="#FBBC05"/>
			<path d="M21.6423 9.60422C25.1176 9.60422 27.4619 11.0754 28.7986 12.3048L34.0219 7.30685C30.814 4.38469 26.6393 2.59108 21.6423 2.59108C14.4037 2.59108 8.15214 6.66192 5.10867 12.5868L11.0928 17.1414C12.5941 12.7683 16.7481 9.60422 21.6423 9.60422Z" fill="#EB4335"/>
			</svg>
		</div>
		
		{/*
      <h2>Sign Up</h2>
      <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} /><br/>
      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /><br/>
      {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} /><br/>
	  
      {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}<br/>
	  <input type="file" accept="image/*" onChange={handleImageChange} />
      <br/><label>
        Remember Me
        <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
      </label><br/>
      <button onClick={handleSignUp}>Sign Up</button>
	  <br/><br/>
		<p style = {{fontSize: "12px", margin: "0px",}}>Have an account already? <Link to="/SignIn"> Sign In</Link></p>*/}
    </div>
  );
};

export default SignUp;
