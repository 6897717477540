import TickerForm from '../TickerForm';
import React, { useState, useEffect, useContext } from 'react';
import { useUser } from '../context/UserContext';
import SignInPage from '../pages/signinpage';
import SignIn from '../components/SignIn';
import UserContext from '../context/UserContext';
import axios from 'axios';
//
const Dashboard = () => {
	const [stockData, setStockData] = useState(null);
	const { user, isLoggedIn } = useUser();
	const { setUser } = useContext(UserContext);
	const [marketOpen, SetMarketOpen] = useState(false);
	const handleFetchData = (data) => {
		setStockData(data);
	};	
	const [marketPrice, setMarketPrice] = useState(null);
  const [VIXmarketPrice, setVIXMarketPrice] = useState(null);
  const timestamp = new Date().getTime(); 
  

  useEffect(() => {
    const fetchFavoriteStockData = async (ticker) => {
        const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
        const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?interval=1d&range=1d`;
        const encodedApiUrl = encodeURIComponent(apiUrl);
        const response = await axios.get(proxyUrl + encodedApiUrl);

        const chartData = response.data.chart.result[0];
        const currentPrice = chartData.meta?.regularMarketPrice;
        const previousClose = chartData.meta?.chartPreviousClose;

        // Calculate the daily change
        const dailyChange = currentPrice - previousClose;

        // Calculate the percentage change
        const percentageChange = ((currentPrice - previousClose) / previousClose) * 100;

        return {
            ticker: ticker,
            price: currentPrice ? parseFloat(currentPrice).toFixed(2) : null,
            dailyChange: dailyChange ? parseFloat(dailyChange).toFixed(2) : null,
            percentageChange: percentageChange ? percentageChange.toFixed(2) : null
        };
    };

    const fetchShortName = async (ticker) => {
        const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
        const apiUrl = `https://query1.finance.yahoo.com/v1/finance/search?q=${ticker}`;
        const encodedApiUrl = encodeURIComponent(apiUrl);
        const response = await axios.get(proxyUrl + encodedApiUrl);

        const quotes = response.data.quotes;
        if (quotes.length > 0) {
            return quotes[0].shortname;
        } else {
            return "Unknown"; // If no short name is found
        }
    };

    const fetchFavoritesData = async () => {
        try {
            if (!user || !user.displayname) {
                return;
            }

            const favoritesResponse = await axios.get(`https://app.stocksleuth.xyz:5000/auth/favorites?displayName=${user.displayname}`);
            const favorites = favoritesResponse.data.favorites;

            const stockDataArray = [];

            for (const ticker of favorites) {
                if (ticker !== "") { // Ignore empty tickers
                    const [stockDataItem, shortName] = await Promise.all([
                        fetchFavoriteStockData(ticker),
                        fetchShortName(ticker)
                    ]);

                    stockDataArray.push({
                        ...stockDataItem,
                        shortName: shortName
                    });
                }
            }

            setStockData(stockDataArray);
        } catch (error) {
            console.error('Error fetching favorites data:', error);
        }
    };

    if (isLoggedIn) {
        fetchFavoritesData();
    }
}, [isLoggedIn]);





  return (
    <div style={{width: "100%", height: "100vh",}}>
	{/*

	<div style={{display: "flex", flexWrap: "wrap", width: "100%", height: "100%", position: "relative", top: "10.74%", left: "1.7578125vh", overflow: "hidden"}}>
		<div style={{marginRight: '2.74%', marginBottom: '-10vh', width: '57.36%', height: '52.83vh', background: '#E6E8FD', borderRadius: 57}} />
		<div style={{marginBottom: '-10vh', width: "35.68%", height: '52.83%', background: '#F0FDE6', borderRadius: 54}} />
		<div style={{marginRight: '2.74%', width: '51.97%', height: '31.93%', background: '#FDE6F6', borderRadius: 57}} ><p style={{fontSize: 20,marginTop: '20px', marginLeft: '40px', textAlign: 'left'}}>Your Portfolio</p></div>
		<div style={{width: '41.26%', height: '31.93%', background: '#FDF4E6', borderRadius: 54}} />
	</div>
	*/}
	{isLoggedIn ? (
	<div>
		<div className="text-wrapper-39">Hello {user.displayname} 👋🏼,</div>
				<div className="overview">
					<div className="overlap-989">
						
					</div>
				</div>
				
				
				
				
				<div className="bigbox">
					<div className="overlap-9" style={{top:0}}>
						<div style = {{height: '100%'}}>
							<h3 style={{margin:0,paddingLeft: 38, paddingTop: 30, textAlign: 'left'}}>Stocks</h3>
						</div>
					</div>
				</div>
				<div className="favebox">
    <div className="overlap-9" style={{ top: 0 }}>
        <div style={{ height: '100%' }}>
            <h3 style={{ margin: 0, paddingLeft: 38, paddingTop: 30, textAlign: 'left' }}>Your Favorites</h3>
            {/* Display the stock data */}
            <hr style={{ width: "95%" }} />
            {stockData && stockData.map((stock) => (
                <div key={stock.ticker}>
                    <a href={`/stock/${stock.ticker}`} style={{ textDecoration: "none", color: "inherit" }}>
                        <p style={{ margin: 0, fontSize: 16, paddingLeft: 38, display: "flex", justifyContent: "space-between", paddingRight: 38 }}>
                            {stock.ticker}
                            <span style={{ textAlign: "right" }}>${stock.price}</span>
                        </p>
                        <p style={{ color: "grey", fontSize: 11, paddingLeft: 38, display: "flex", justifyContent: "space-between", paddingRight: 38, margin: 0 }}>
                            <span style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12ch", display: "inline-block" }}>
                                {stock.shortName}
                            </span>
                            <span style={{ color: stock.dailyChange > 0 ? "green" : "red", textAlign: "right", display: "flex", alignItems: "center" }}>
                                {stock.dailyChange > 0 ? (
                                    <img style={{ width: 12, height: 12 }} alt="Arrow up" src="https://c.animaapp.com/GEOYKsql/img/arrow-up-1-2.svg" />
                                ) : (
                                    <img style={{ width: 12, height: 12 }} alt="Arrow up" src="https://c.animaapp.com/GEOYKsql/img/arrow-up-1.svg" />
                                )}
                                ${Math.abs(stock.dailyChange).toFixed(2)} ({Math.abs(stock.percentageChange)}%)
                            </span>
                        </p>
                    </a>
                    <hr style={{ width: "95%" }} />
                </div>
            ))}
        </div>
    </div>
</div>

				
				
				
				
				
				
				
				
				
				
			</div>
				
	) : (<div style={{width:'100vw', height: '100vh', position: 'static', left: '0px'}}><SignInPage/></div>)}
    </div>
  );
};
export default Dashboard;