import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../Login.css';

const ProfilePage = () => {
  const { username } = useParams();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`https://app.stocksleuth.xyz:5000/profile/${username}`);
        const profileData = response.data;

        // Fetch profile image URL separately
        const profileImageResponse = await axios.get('https://app.stocksleuth.xyz:5000/auth/getpfp', {
          params: {
            username: profileData.username,
          },
        });

        // Set the profile image URL in the profileData object
        profileData.profile_image = profileImageResponse.data.profile_image;

        // Set the profile data in state
        setProfileData(profileData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [username]);

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <img className="pfp124" src={`data:image/jpeg;base64,${profileData.profile_image}`} alt="Profile" />
      <h3 className="nameBig">{profileData.displayname}</h3>
      <p className='pronounsgrey'>{profileData.pronouns}</p>
      <p className='bioDesc'>{profileData.bio}</p>
      
    </div>
  );
};

export default ProfilePage;
