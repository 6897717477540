import React, { useState, useContext } from 'react';
import UserContext from '../context/UserContext';
import axios from 'axios';
import '../Login.css';
import seePass from '../images/seePassword.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  navigate,
  useLocation,
  Navigate,
  useNavigate,
  NavLink
} from "react-router-dom";

const AccountPage = () => {
  const { user, setUser, isLoggedIn } = useContext(UserContext);
  
  const [newUsername, setNewUsername] = useState(user?.username || ''); // Add null check here
  const [newEmail, setNewEmail] = useState(user?.email || ''); // Add null check here
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [image, setImage] = useState(null);
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [pronouns, setPronouns] = useState(user?.pronouns || '');
  const [bio, setBio] = useState(user?.bio || '');
  const [pfp, setPfp] = useState(null);
  const [selectedPronoun, setSelectedPronoun] = useState(user?.pronouns || '');
  
 
  const [customPronoun, setCustomPronoun] = useState('');
  
  console.log(user?.pronouns);
  const handleUpdateAccount = async () => {
    try {
      const formData = new FormData();
      formData.append('username', user.username);
      formData.append('displayName', displayName);
      formData.append('pronouns', pronouns);
      formData.append('bio', bio);
      formData.append('pfp', image);

      const response = await axios.post('https://app.stocksleuth.xyz:5000/auth/update', formData);

      setUser(response.data.updatedUser);
      console.log('Account updated successfully:', response.data);
      ///
      window.location.reload();
    } catch (error) {
      console.error('Account update failed', error);
    }
  };
	
	
	const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Set the canvas dimensions to your desired size
          const desiredWidth = 256;
          const desiredHeight = 256;
  
          // Calculate the aspect ratio of the image
          const aspectRatio = img.width / img.height;
  
          // Calculate the dimensions of the portion to be cropped
          let cropWidth = img.width;
          let cropHeight = img.height;
          let cropX = 0;
          let cropY = 0;
  
          if (aspectRatio > 1) {
            // Landscape-oriented image
            cropWidth = img.height;
            cropHeight = img.height;
            cropX = (img.width - img.height) / 2;
          } else if (aspectRatio < 1) {
            // Portrait-oriented image
            cropWidth = img.width;
            cropHeight = img.width;
            cropY = (img.height - img.width) / 2;
          }
  
          // Set the canvas dimensions to your desired size
          canvas.width = desiredWidth;
          canvas.height = desiredHeight;
  
          // Draw the image on the canvas with the desired size and crop
          ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, desiredWidth, desiredHeight);
  
          // Convert the canvas content to a Blob object (compressed image file)
          canvas.toBlob((blob) => {
            setImage(blob);
          }, 'image/jpeg', 0.7);
        };
      };
  
      reader.readAsDataURL(file);
    }
  };
  


  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedPronoun(value);
    setPronouns(value)
    if (value == 'other') {
      setPronouns('');
    }
    // Reset customPronoun if another pronoun is selected
    if (value !== 'other') {
      setCustomPronoun('');
    }
  };
  return (
  <div style={{height: '100vh', width: '100vw ', display: 'flex', alignItems: "center",
			justifyContent: "center",
			textAlign: "center", position: 'absolute', left: '0px'}}>
      {isLoggedIn ? (<>
      <div className="text-wrapper-39">Settings</div>
    
  <div className="AccountForm">
    <div className="AccountHeading">Your Profile</div>
    <NavLink to={`/profile/${user?.displayname}`}><div className="see">View Your Profile</div></NavLink>
    <div className="SaveChanges" onClick={handleUpdateAccount}>Save Changes</div>
    <div style={{left: 71, position: "absolute", top: "50px"}}>
			<h2 className = "DisName" style = {{margin: "0px", position: 'relative', left: 0, top: 0, textAlign: 'left', paddingBottom: "6px"}}>Username*</h2>
			<div className = "EmailInput">
			<input value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}className="EmailPlaceholder" style={{color: '#A7A3FF',padding: '0px',position: 'relative',left: '15px', border: 'none', background: 'none' }}type="text" placeholder={user?.displayname || ''}  />
			</div>
      <h2 className="Pronouns" style={{ margin: "0px", position: 'relative', left: 0, top: 0, textAlign: 'left', paddingBottom: "6px", paddingTop: "10px" }}>Pronouns</h2>
      <div className="EmailInput">
        {selectedPronoun === 'other' ? (
          <input
            className="ProPlaceholder"
            style={{ color: '#A7A3FF', padding: '0px', position: 'relative',left: '15px', border: 'none', background: 'none' }}
            type="text"
            placeholder="Enter custom pronouns"
            value={pronouns}
            onChange={(e) => setPronouns(e.target.value)}
          />
        ) : (
          <select
            className="ProPlaceholder"
            style={{ color: '#A7A3FF', padding: '0px', position: 'relative', left: "15px", border: 'none', background: 'none' }}
            value={selectedPronoun}
            onChange={handleChange}
            
          >
            {(!user?.pronouns || user?.pronouns === '') && <option value="" disabled>Select pronouns</option>}
            <option value="they/them">They/Them</option>
            <option value="he/him">He/Him</option>
            <option value="she/her">She/Her</option>
            <option value="other">Other</option>
          </select>
        )}
      </div>
      <h2 className = "Bio" style = {{margin: "0px", position: 'relative', left: 0, top: 0, textAlign: 'left', paddingBottom: "6px",paddingTop: "10px"}}>Bio</h2>
			<div className = "BioInput">
      <input 
  value={bio}
  onChange={(e) => setBio(e.target.value)}
  className="bioPlaceholder"
  style={{ color: '#A7A3FF', padding: '0px', position: 'relative', left: '15px', top: "15px", border: 'none', background: 'none' }}
  type="text" 
  placeholder={user ? user.bio || '' : ''}  
/>

			</div>
      
      <div className='pfp'>
      {image ? (
  // Check if image state has a value (image blob)
  <img
    src={URL.createObjectURL(image)} // Use URL.createObjectURL to display the selected image
    alt="pfp"
    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: "8px" }}
  />
) : (
  user && user.profile_image ? (
    <img
      src={`data:image/jpeg;base64,${user.profile_image}`} // Use user.profile_image directly
      alt="pfp"
      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: "8px" }}
    />
  ) : (
    <></>
  )
)}

  <input
    type="file"
    accept="image/*"
    style={{ position: 'absolute', bottom: 0, right: 0, opacity: 0, cursor: 'pointer', width: '100%', height: '100%', borderRadius: "8px" }}
    onChange={handleImageChange}
  />
</div>
      </div>

    
    </div></>) : (
        <div>
		
        </div>
      )}</div>
  );
};

export default AccountPage;
