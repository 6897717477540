import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Tournaments = () => {
  const [showModal, setShowModal] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    start_date: '',
    end_date: '',
    creator: '',
    allowed_markets: '',
    contestants: '',
    return: '',
    isPublic: false // Assuming default value for isPublic is false
  });

  useEffect(() => {
    const fetchCurrentDate = async () => {
      try {
        const response = await axios.get('http://worldtimeapi.org/api/timezone/Etc/UTC');
        setCurrentDate(new Date(response.data.utc_datetime));
      } catch (error) {
        console.error('Error fetching current date:', error);
      }
    };

    const fetchTournaments = async () => {
      try {
        const response = await axios.get('https://app.stocksleuth.xyz:5000/getTournaments', {
          params: { public: true }
        });
        setTournaments(response.data);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchCurrentDate();
    fetchTournaments();
  }, []);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://app.stocksleuth.xyz:5000/CreateTournament', formData);
      setShowModal(false);
      setFormData({
        name: '',
        start_date: '',
        end_date: '',
        creator: '',
        allowed_markets: '',
        contestants: '',
        return: '',
        isPublic: false
      });
    } catch (error) {
      console.error('Error creating tournament:', error);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2>Create a New Tournament</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Tournament Name" required />
              <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} required />
              <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} required />
              <input type="text" name="creator" value={formData.creator} onChange={handleChange} placeholder="Creator" required />
              <input type="text" name="allowed_markets" value={formData.allowed_markets} onChange={handleChange} placeholder="Allowed Markets" required />
              <input type="text" name="contestants" value={formData.contestants} onChange={handleChange} placeholder="Contestants (JSON)" required />
              <input type="number" step="0.01" name="return" value={formData.return} onChange={handleChange} placeholder="Return" required />
              <label htmlFor="isPublic">Public:</label>
              <input type="checkbox" name="isPublic" checked={formData.isPublic} onChange={handleChange} />
              <button type="submit">Create Tournament</button>
            </form>
          </div>
        </div>
      )}

      {/* Your existing UI */}
      
      <div style={{ bottom: "0px", position: "absolute", left: "0px", right: "306px", top: "117px" }}>
        <div style={{ width: "100%", height: 300, display: "flex", gap: "20px" }}>
          {/* Your existing UI */}
        </div>
        <div style={{ position: "absolute", bottom: "0px", left: "0px", boxShadow: "0px 10px 60px rgba(225.83, 236.19, 248.63, 0.50)", backgroundColor: "white", height: 100, width: "40%", marginBottom: 30, marginLeft: 30, borderRadius: 20 }}>
          <button onClick={() => setShowModal(true)}>Create a Tournament</button>
        </div>
        <div style={{ position: "absolute", bottom: "0px", left: "41%", boxShadow: "0px 10px 60px rgba(225.83, 236.19, 248.63, 0.50)", backgroundColor: "white", height: 300, width: "40%", marginBottom: 30, marginLeft: 30, borderRadius: 20, overflowY: 'auto' }}>
          <h2>Tournaments List</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Creator</th>
              </tr>
            </thead>
            <tbody>
              {tournaments.map((tournament, index) => (
                <tr key={index}>
                  <td>{tournament.Name}</td>
                  <td>{new Date(tournament.Start_date).toLocaleDateString()}</td>
                  <td>{new Date(tournament.End_date).toLocaleDateString()}</td>
                  <td>{tournament.Creator}</td>
                  <td>
                    {currentDate && new Date(currentDate) < new Date(tournament.End_date) && (
                      <button>Join</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tournaments;
