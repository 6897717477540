// StockPage.js

import React, { useState, useEffect, useCallback,  useRef  } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Switch from 'react-switch';
import dayjs from 'dayjs';
import { Routes, Route, useParams } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import '../stockpagestyle.css';
import emptys from '../images/Starempty-star.png';
import filleds from '../images/Starfilled-star.png';
import FlyIcon from '../images/SendMessage.png';
import gradientRec from '../images/gradientRec.svg';


const StockPage = ({ match }) => {
  const { ticker } = useParams();
  const [chartData, setChartData] = useState(null);
  const [sma50Enabled, setSma50Enabled] = useState(false);
  const [sma100Enabled, setSma100Enabled] = useState(false);
  const [sma200Enabled, setSma200Enabled] = useState(false);
  const [interval, setInter2val] = useState('1d');
  const [range, setRange] = useState('ytd');
  const [currentPrice, setCurrentPrice] = useState(null); // New state for current price
  const [prevPrice, setPrevPrice] = useState(null);
  const [showCandlestick, setShowCandlestick] = useState(true);
  const { user, isLoggedIn } = useUser();
  const [logoInfo, setLogoInfo] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false); // New state for favorite status
  const [nameInfo, setnameInfo] = useState(null);
  const [messages, setMessages] = useState([]); // State for chat messages
  const [newMessage, setNewMessage] = useState('');
  const [messagStats, setMesageStats] = useState(1);
  const [fetchingOlderMessages, setFetchingOlderMessages] = useState(false);
  const chatBoxRef = useRef(null);
  
  
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
        const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=1d&range=1d`;
		const encodedApiUrl = encodeURIComponent(apiUrl);
		
        const response = await axios.get(proxyUrl + encodedApiUrl);
        const data = response.data.chart.result[0].indicators.quote[0];
        const price = parseFloat(data.close.slice(-1)[0]).toFixed(2);
		
        setCurrentPrice(price);
		setPrevPrice(response.data.chart.result[0].meta.chartPreviousClose);
		console.log(response.data.chart.result[0].meta.chartPreviousClose);
		const logoResponse = await axios.get(`https://app.stocksleuth.xyz:5000/auth/getwebsite?ticker=${ticker}`);
        setLogoInfo(logoResponse.data.lastElement.text);
		
		
		const nameResponse = await axios.get(`${proxyUrl}${encodeURIComponent(`https://query1.finance.yahoo.com/v1/finance/search?q=${ticker}`)}`);
        setnameInfo(nameResponse.data.quotes[0].longname);
		
      } catch (error) {
        console.error('Failed to fetch current price', error);
      }
    };

    fetchCurrentPrice();
  }, [ticker]);


  useEffect(() => {
    const checkIfFavorite = async () => {
      try {
        if (!user?.displayname) return; // Check if user or displayname is null
        const response = await axios.get(`https://app.stocksleuth.xyz:5000/auth/checkFavorite?ticker=${ticker}&displayName=${user.displayname}`);
        setIsFavorite(response.data.isFavorite);
      } catch (error) {
        console.error('Failed to check if ticker is in favorites', error);
      }
    };
  
    if (isLoggedIn) {
      checkIfFavorite();
    }
  }, [isLoggedIn, ticker, user?.displayname]);

  // Function to toggle favorites
  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        // Remove from favorites
        await axios.post('https://app.stocksleuth.xyz:5000/auth/removeFavorite', { ticker, displayName: user.displayname });
      } else {
        // Add to favorites
        await axios.post('https://app.stocksleuth.xyz:5000/auth/addFavorite', { ticker, displayName: user.displayname });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Failed to toggle favorite', error);
    }
  };




  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
        const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=high?&interval=${interval}&range=${range}`;
		const encodedApiUrl = encodeURIComponent(apiUrl);
        const response = await axios.get(proxyUrl + encodedApiUrl);
        const data = response.data.chart.result[0];

        const sma50 = sma(data.indicators.quote[0].close, 50);
        const sma100 = sma(data.indicators.quote[0].close, 100);
        const sma200 = sma(data.indicators.quote[0].close, 200);

        setChartData({
          series: [
            !showCandlestick && {
              name: 'candle',
              type: 'candlestick',
              data: data.timestamp.map((timestamp, index) => ({
                x: new Date(timestamp * 1000),
                y: [
                  data.indicators.quote[0].open[index],
                  data.indicators.quote[0].high[index],
                  data.indicators.quote[0].low[index],
                  data.indicators.quote[0].close[index],
                ],
              })),
            },
            showCandlestick && {
              name: 'close',
              type: 'line',
              data: data.indicators.quote[0].close.map((value, index) => ({
                x: new Date(data.timestamp[index] * 1000),
                y: value,
              })),
            },
            sma50Enabled && {
              name: 'SMA(50)',
              type: 'line',
              data: sma50.map((value, index) => ({
                x: new Date(data.timestamp[index] * 1000),
                y: value,
              })),
            },
            sma100Enabled && {
              name: 'SMA(100)',
              type: 'line',
              data: sma100.map((value, index) => ({
                x: new Date(data.timestamp[index] * 1000),
                y: value,
              })),
            },
            sma200Enabled && {
              name: 'SMA(200)',
              type: 'line',
              data: sma200.map((value, index) => ({
                x: new Date(data.timestamp[index] * 1000),
                y: value,
              })),
            },
          ].filter(Boolean),
          options: {
			 plotOptions: {
				  candlestick: {
					colors: {
					  upward: '#2cd42c', // Color for upward (bullish) candles
					  downward: '#ff5733', // Color for downward (bearish) candles
			 },},},
            stroke: {
              width: [2], // Adjust the width as needed
              colors: ['#808EF6'], // Specify your desired colors
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
              marker: {
                show: false,
              },
              onDatasetHover: {
                highlightDataSeries: false,
              },
              followCursor: false,
              intersect: false,
              custom: function ({ seriesIndex, dataPointIndex, w }) {
                const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
                const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
                const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
                const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex];
                return ('');
              },
            },
            chart: {
              type: showCandlestick ? 'candlestick' : 'line',
              
              toolbar: {
                autoSelected: 'none',
                show: false,
              },
            },
            states: {
              normal: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
            },
            grid: {
              position: 'back',
              borderColor: '#FDFBFF',
            },
            xaxis: {
              type: 'category',
              tickAmount: 4,
			  axisTicks: {
				  show: false,
				  
			  },
              labels: {
                formatter: function (val) {
                  // Check if chartData and its options are available
                  if (chartData && chartData.options && chartData.options.chart) {
                    

                    // Customize date-time formats based on the selected range
                    if (range === '1d') {
                      // Format for 1 day
                      return dayjs(val).format('HH:mm');
                    } else if (['5d', '1mo', '3mo', '6mo'].includes(range)) {
                      // Format for 5 days, 1 month, 3 months, 6 months
                      return dayjs(val).format('DD.MM');
                    } else if (['1y', '2y', '5y', 'ytd', 'max'].includes(range)) {
                      // Format for 1 year, 2 years, 5 years, year-to-date, max
                      return dayjs(val).format('MMM YYYY');
                    }
                  }

                  // Default format for other cases
                  return dayjs(val).format('MMM YY');
                },
				rotate: 0,
              },
              crosshairs: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
				//max: Math.max(...data.indicators.quote[0].close) * 1.05,
              opposite: true,
              tickAmount: 10,
              labels: {
                formatter: function (val, index) {
                  return val !== null ? val.toFixed(2) : val;
                },
              },
              forceNiceScale: true,
              tooltip: {
                enabled: false,
              },
              crosshairs: {
                show: false,
              },
            },
            markers: {
              size: 0,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchStockData();
  }, [ticker, interval, range, sma50Enabled, sma100Enabled, sma200Enabled, showCandlestick]);

  const sma = (data, period) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (i >= period - 1) {
        const sum = data.slice(i - period + 1, i + 1).reduce((acc, val) => acc + val, 0);
        result.push(sum / period);
      } else {
        result.push(null);
      }
    }
    return result;
  };


  const [userColors, setUserColors] = useState({});
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action of the Enter key (e.g., form submission or new line in textarea)
      handleSendMessage(); // Call the function to handle sending the message
    }
  };
  useEffect(() => {
    const assignColors = () => {
      const colors = [
        "#0000FF", // blue
        "#FF0000", // red
        "#8A2BE2", // purple
        "#FF69B4", // pink
        "#1E90FF", // light blue
        "#008000", // dark green
        "#00FF7F", // spring green
        "#B22222", // wine red
        "#DAA520", // brownish orange
        "#FF4500", // orange
        "#2E8B57", // greyish green
        "#5F9EA0", // blueish grey
        "#D2691E", // dark orange
      ];
      const assignedColors = { ...userColors }; // Copy existing colors
  
      messages.forEach((msg) => {
        if (!assignedColors[msg.username]) {
          const randomColor = colors[Math.floor(Math.random() * colors.length)];
          assignedColors[msg.username] = randomColor;
        }
      });
  
      setUserColors(assignedColors); // Update the state with the new or preserved colors
    };
  
    assignColors();
  }, [ticker, messages]);
  
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`https://app.stocksleuth.xyz:5000/api/chat/${ticker}`);
        const data = await response.json();
        setMessages(data);
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 60000);

    return () => clearInterval(intervalId);
  }, [ticker]);

  

  const handleSendMessage = async () => {
    try {
      if (!newMessage.trim()) return;
      await axios.post('https://app.stocksleuth.xyz:5000/api/chat', {
        ticker,
        message: newMessage,
        username: user?.displayname,
      });
      setNewMessage('');
      setMessages((prevMessages) => [{ username: user?.displayname, message: newMessage }, ...prevMessages ]);
      setTimeout(() => {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }, 0);
    } catch (error) {
      console.error('Failed to send message', error);
    }
  };

  const handleScroll = async (e) => {
    if (e.target.scrollTop === 0 && !fetchingOlderMessages) {
      setFetchingOlderMessages(true);
      try {
        const oldestMessageId = messages[0]?.id;
        const response = await fetch(`https://app.stocksleuth.xyz:5000/api/chat/${ticker}`);
        const olderMessages = await response.json();
        setMessages((prevMessages) => [...olderMessages, ...prevMessages]);
      } catch (error) {
        console.error('Error fetching older messages:', error);
      } finally {
        setFetchingOlderMessages(false);
      }
    }
  };
  


  const handleIntervalChange = (newInterval) => {
    setInter2val(newInterval);
  };

  const handleRangeChange = (newRange) => {
    setRange(newRange);
  };

  const handleChartTypeChange = (checked) => {
    setShowCandlestick(checked);
  };
  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };
  
  
  
  
  /*const handleSendMessage = async () => {
    try {
      if (!newMessage.trim()) return;
      const response = await axios.post('https://app.stocksleuth.xyz:5000/api/chat', {
        ticker,
        message: newMessage,
        username: user?.displayname,  // Sending the user's username
      });
      //setMessages([...messages, { text: newMessage, user: user?.displayname }]);
      setNewMessage('');
      setMesageStats(messagStats+1);
    } catch (error) {
      console.error('Failed to send message', error);
    }
  };*/
  
const handleRangeButtonClick = (selectedRange) => {
  let selectedInterval;

  switch (selectedRange) {
    case '1d':
      selectedInterval = '1m';
      break;
    case '5d':
      selectedInterval = '5m';
      break;
    case '1mo':
      selectedInterval = '1h';
      break;
    case '3mo':
    case '6mo':
      selectedInterval = '1d';
      break;
    case '1y':
      selectedInterval = '1d';
      break;
    case '2y':
    case '5y':
      selectedInterval = '1wk';
      break;
    case 'max':
      selectedInterval = '1mo';
      break;
    case 'ytd':
      selectedInterval = '1d';
      break;
    default:
      selectedInterval = '1d';
  }

  setRange(selectedRange);
  setInter2val(selectedInterval);
  //fetchStockData();
};
  const StockPurchaseForm = ({ ticker }) => {
    const [shares, setShares] = useState('');
    const [purchasePrice, setPurchasePrice] = useState('');
    const [message, setMessage] = useState('');
    const [orderType, setOrderType] = useState('buy');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    const handlePurchase = async () => {
      let finalShares = orderType === 'sell' ? -shares : shares;
      let finalPurchasePrice = purchasePrice;
      let finalDatetime = new Date().toISOString();

      try {
        if (!purchasePrice) {
          const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
          const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=1d&range=1d`;
			const encodedApiUrl = encodeURIComponent(apiUrl);
          const response = await axios.get(proxyUrl + encodedApiUrl);
          const data = response.data.chart.result[0].indicators.quote[0];
          const currentPrice = parseFloat(data.close.slice(-1)[0]).toFixed(2);

          finalPurchasePrice = currentPrice;
        }

        if (selectedDate || selectedTime) {
          const date = selectedDate || new Date().toLocaleDateString();
          const time = selectedTime || new Date().toLocaleTimeString();
          finalDatetime = new Date(`${date} ${time}`).toISOString();
        }

        const data = {
          ticker,
          shares: finalShares,
          purchasePrice: finalPurchasePrice,
          username: user.username, // Assuming 'user' is defined in the outer scope
          datetime: finalDatetime,
        };

        await axios.post('https://app.stocksleuth.xyz:5000/auth/purchase', data);
        setMessage('Stock purchased successfully');
      } catch (error) {
        console.error('Stock purchase failed', error);
        setMessage('Stock purchase failed');
      }
    };



    




    return (
      <div>
		<p>
          {ticker}  |  Current Price: {currentPrice ? `$${currentPrice}` : 'Loading...'}
        </p><br/>
        <label>
          Shares:
          <input type="number" value={shares} onChange={(e) => setShares(e.target.value)} />
        </label>
        <br />
        <label>
          Purchase Price:
          <input type="number" value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)} />
        </label>
        <br />
        <label>
          Date:
          <input type="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
        </label>
        <br />
        <label>
          Time:
          <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} />
        </label>
        <br />
        <p style={{ color: 'green', margin: '0px' }}>
          <input
            type="radio"
            value="buy"
            checked={orderType === 'buy'}
            onChange={(e) => setOrderType(e.target.value)}
          />{' '}
          Buy
        </p>
        <p style={{ color: 'red', margin: '0px' }}>
          <input
            type="radio"
            value="sell"
            checked={orderType === 'sell'}
            onChange={(e) => setOrderType(e.target.value)}
          />{' '}
          Sell
        </p>
        <br />
        <button onClick={handlePurchase}>Submit Order</button>
        <p>{message}</p>
      </div>
    );
  };
  return (
    <div>
		<div className="header-logo" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
			{logoInfo && (
          <img
            src={`https://logo.clearbit.com/${logoInfo}`}
            alt= {ticker}
            style={{ width: '80px', height: '80px'}}
          />
        )}
		</div>
		<div className="header-Name" style={{ fontSize: '20px', fontFamily: 'Poppins-Medium', display: 'flex', alignContent: 'left', alignItems: 'center', justifyContent: 'left', paddingLeft: '44px'}}>
			{nameInfo}
			{/*add changing and favoriting*/}
			<img className="star" alt="favorite" src={isFavorite ? filleds : emptys} onClick={toggleFavorite} />
		</div>
		<div className="header-Price" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
			{currentPrice > prevPrice ? (<>
				{currentPrice ? `$${currentPrice}⬆️` : 'Loading...'}
			</>):(<>
				{currentPrice ? `$${currentPrice}⬇️` : 'Loading...'}
			</>)}
		</div>
		<div className="header-IdkWhatYet" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
		</div>
    <div className="header-IdkWhatYet2" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
      <div className='Reccomendation' style={{position: 'absolute', left: 0, width: '50%'}}>Strong Buy</div>
      <img src={gradientRec} style={{right: '10px', position: 'absolute', width: 'calc(50% - 10px)'}}/>
		</div>
		<div className="body-RangeSelector" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
      <button className={`range-button ${range === '1d' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1d')}>1d</button>
      <button className={`range-button ${range === '5d' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('5d')}>5d</button>
      <button className={`range-button ${range === '1mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1mo')}>1mo</button>
      <button className={`range-button ${range === '3mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('3mo')}>3mo</button>
      <button className={`range-button ${range === '6mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('6mo')}>6mo</button>
      <button className={`range-button ${range === '1y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1y')}>1y</button>
      <button className={`range-button ${range === '2y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('2y')}>2y</button>
      <button className={`range-button ${range === '5y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('5y')}>5y</button>
      <button className={`range-button ${range === 'max' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('max')}>max</button>
      <button className={`range-button ${range === 'ytd' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('ytd')}>ytd</button>
    </div>

		<div className="body-Chart" style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
			{chartData && (
				<Chart
				  options={chartData.options}
				  series={chartData.series}
				  type={showCandlestick ? 'candlestick' : 'line'}
				  height={'100%'}
				  width={'100%'}
				/>
			)}
		</div>
		<div className="body-Data" style={{display: 'flex', alignContent: 'center', justifyContent: 'left'}}>
      <h2 style={{ marginLeft: "20px", fontSize: '18px', fontFamily: 'Poppins-Medium', display: 'flex', alignContent: 'left', alignItems: 'left', justifyContent: 'left', marginTop: "10px", marginLeft: "12px"}} >Live Chat</h2>
      <div className='ChatBox' style={{ display: 'flex', paddingLeft: 5, paddingTop: 10, top: 36, left: 6, right: 6, bottom: 52, position: "absolute", overflowY: 'auto' }} ref={chatBoxRef} onScroll={handleScroll}>
      <ul style={{ width: '100%' }}>
      {messages?.slice().reverse().map((msg, index) => (
  <li key={index} className="chat-message" style={{ display: 'flex', alignContent: 'left', alignItems: 'left', justifyContent: 'left' }}>
    <b style={{ color: userColors[msg.username] }}>{`${msg.username}`}</b>{`: ${msg.message}`}
  </li>
))}

      </ul>
    </div>

      <input
            type="text"
            value={newMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            placeholder="Send a message"
            style={{padding: 0, paddingLeft: 20, border: 0, backgroundColor: "#EDEDED", position: "absolute", bottom: 6, left: 6, right: 60, height: 40, borderBottomLeftRadius:30, borderBottomRightRadius: 6, borderTopLeftRadius: 10, borderTopRightRadius: 6 }}
      />

      
      <div onClick={handleSendMessage} className='sendMes' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#808EF6", position: "absolute", bottom: 6, width: 50, right:6, height: 40, borderBottomLeftRadius:6, borderBottomRightRadius: 30, borderTopLeftRadius: 6, borderTopRightRadius: 10 }}>
        <img src={FlyIcon} style={{marginLeft: '-6px', width: '70%', height: '70%'}}/>
      </div>
    </div> 
		<div className="body-idk" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
		</div>
		<div className="body-details" style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
		</div>
		{/*<div>
			<label>
				Chart Type:
				<Switch onChange={handleChartTypeChange} checked={showCandlestick} />
				{showCandlestick ? 'Candlestick' : 'Line Chart'}
			</label>
		</div>*/}
		{/* Rest of the code remains unchanged
		
		<StockPurchaseForm ticker={ticker} />*/}
		<a href="https://clearbit.com" style={{ textDecoration: 'none', color: 'inherit', fontSize: '12px', position: 'absolute', bottom:6, right: 'calc(6.6% + 306px)'}}>Logos provided by Clearbit</a>
    </div>
  );
};

export default StockPage;
